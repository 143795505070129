import axios from "axios";
// export const baseUrl = "http://api.aptech.innovadorhub.com/";
// export const baseUrl = "https://localhost:7290/";
// export const baseUrl = "https://192.168.18.12:5012";
export const Axios = axios.create({
  // baseURL: "http://localhost:5000/api/",

  // baseURL: "https://jnvj91m4-7290.asse.devtunnels.ms/api/",
  // baseURL: "http://innovador.local:82/api/",
  // baseURL: "   ",
  // baseURL: "https://shzfllfr-7290.euw.devtunnels.ms/api/",
  baseURL: "https://webbackend.aptech-education.com.pk/api/",
  // baseURL: "https://pbsnwn99-7290.inc1.devtunnels.ms/api/",
  // baseURL: "http://192.168.18.12:5012/api/",
  // baseURL: `${baseUrl}api`,
  headers: {
    "Content-Type": "application/json",
  },
});

// export const contentUrl = "http://localhost:5000/";
// export const contentUrl = "https://hjdt5pq5-7290.inc1.devtunnels.ms/";
export const contentUrl = "https://webbackend.aptech-education.com.pk/";
// export const contentUrl = "http://192.168.18.12:5012/";
