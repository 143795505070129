import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { encryptData, setItem, Storage } from "../../Services/Storage";
import { Axios } from "../../Services/Axios/index";
import * as yup from "yup";
import Loader from "../../Components/loader";

const validationSchema = yup.object({
  username: yup
    .string("Enter a valid value...")
    .required("This value is required..."),
  password: yup
    .string("Enter your password...")
    .required("Enter your password..."),
});

const adminPermissions = [
  {
    title: "Cities",
    allRights: 0,
    canView: true,
    canAdd: true,
    canUpdate: true,
    canDelete: true,
  },
  {
    title: "Centers",
    allRights: 0,
    canView: true,
    canAdd: true,
    canUpdate: true,
    canDelete: true,
  },
  {
    title: "Course Inquiry",
    allRights: 0,
    canView: true,
    canAdd: true,
    canUpdate: true,
    canDelete: true,
  },
  {
    title: "Placements",
    allRights: 0,
    canView: true,
    canAdd: true,
    canUpdate: true,
    canDelete: true,
  },
  {
    title: "POM",
    allRights: 0,
    canView: true,
    canAdd: true,
    canUpdate: true,
    canDelete: true,
  },
  {
    title: "SOM",
    allRights: 0,
    canView: true,
    canAdd: true,
    canUpdate: true,
    canDelete: true,
  },
  {
    title: "Upcoming Batches",
    allRights: 0,
    canView: true,
    canAdd: true,
    canUpdate: true,
    canDelete: true,
  },
  {
    title: "Events",
    allRights: 0,
    canView: true,
    canAdd: true,
    canUpdate: true,
    canDelete: true,
  },
  {
    title: "Course",
    allRights: 0,
    canView: true,
    canAdd: true,
    canUpdate: true,
    canDelete: true,
  },
  {
    title: "Semester",
    allRights: 0,
    canView: true,
    canAdd: true,
    canUpdate: true,
    canDelete: true,
  },
  {
    title: "Books",
    allRights: 0,
    canView: true,
    canAdd: true,
    canUpdate: true,
    canDelete: true,
  },
  {
    title: "Tickets",
    allRights: 0,
    canView: true,
    canAdd: true,
    canUpdate: true,
    canDelete: true,
  },
  {
    title: "Statuses",
    allRights: 0,
    canView: true,
    canAdd: true,
    canUpdate: true,
    canDelete: true,
  },
  {
    title: "Issues",
    allRights: 0,
    canView: true,
    canAdd: true,
    canUpdate: true,
    canDelete: true,
  },
  {
    title: "Orders",
    allRights: 0,
    canView: true,
    canAdd: true,
    canUpdate: true,
    canDelete: true,
  },
  {
    title: "Settings",
    allRights: 0,
    canView: true,
    canAdd: true,
    canUpdate: true,
    canDelete: true,
  },
];

export const Login = () => {
  const [loading, setLoading] = React.useState(false);
  const [axiosError, setAxiosError] = React.useState(undefined);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      Axios.post("/Users/login", values)
        .then((res) => {
          if (res?.data?.users?.role === "center") {
            setItem("center", res?.data?.center);
            dispatch({
              type: "setCenter",
              payload: encryptData(res?.data?.center),
            });
          }

          setLoading(false);
          setItem("token", res?.data?.token);
          Storage.setItem("user", res?.data?.users);
          Storage.setItem("center", res?.data?.center);
          setItem("role", { role: res?.data?.users?.role });
          setItem("name", { role: res?.data?.users?.name });
          dispatch({ type: "setLogin", payload: encryptData(true) });
          dispatch({ type: "setUser", payload: encryptData(res?.data?.users) });
          dispatch({
            type: "setRole",
            payload: encryptData({ role: res?.data?.users?.role }),
          });
          navigate("/");
        })
        .catch((err) => {
          setSubmitting(false);
          setLoading(false);
          if (err?.response?.status) {
            setAxiosError(err?.response?.data);
          }
        });
    },
  });

  return (
    <>
      <section className="login_section">
        <div className="loginBody">
          <div className="login_form">
            <h1 className="m-0">Welcome back</h1>
            <p className="m-0">Welcome back! Please enter your details.</p>

            <form onSubmit={formik.handleSubmit}>
              <label>Email</label>
              <input
                type="text"
                id="username"
                name="username"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="inputform"
                placeholder="Enter your email"
                autoComplete="username"
              />
              <small className="text-danger">
                {formik.touched.username && formik.errors.username}
              </small>
              <label>Password</label>
              <input
                type="password"
                className="inputform"
                autoComplete="password"
                id="password"
                name="password"
                placeholder="Enter your password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.password && formik.errors.password}
              </small>
              <small className="text-danger" style={{ marginTop: "15px" }}>
                {axiosError}
              </small>
              <button
                className="inline-flex w-full justify-center rounded-md border border-transparent btn btn-theme px-4 py-2 text-base font-medium  shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
                type="submit"
                disabled={!formik.isValid}
              >
                {formik.isSubmitting ? (
                  <>
                    <Loader size={"size-4"} /> loading...
                  </>
                ) : (
                  "Sign in"
                )}
              </button>
            </form>
          </div>
          <div className="loginFooter">
            <p>&copy; Aptech 2023</p>
          </div>
        </div>
        <div className="loginFigure">
          <img
            src="https://aptech-learning.netlify.app/assets/images/icon-aptech.png"
            alt=""
          />
          <div className="overlay"></div>
        </div>
      </section>
    </>
  );
};
